import { Button } from "../../ui/buttons";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import "./modelsList.css"

import all from "./models/all.png"
import iphone16promax from "./models/iPhone/16 Pro Max.png"
import iphone16pro from "./models/iPhone/16 Pro.png"
import iphone16 from "./models/iPhone/16.png"
import iphone15promax from "./models/iPhone/15 Pro Max.png";
import iphone15pro from "./models/iPhone/15 Pro.png";
import iphone15 from "./models/iPhone/15.png";
//import iphone14pro from "./models/iPhone/14 Pro.png";
import iphone14 from "./models/iPhone/14.png";
import iphone13 from "./models/iPhone/13.png";
import iphone12 from "./models/iPhone/12.png";
import iphone11 from "./models/iPhone/11.png";

import macbookair from "./models/Mac/MacBook Air.png"

import ipadair from "./models/iPad/Air.png"
import ipad9 from "./models/iPad/9.png"

import watchseries10 from "./models/Watch/Series 10.png"
import watchseries9 from "./models/Watch/Series 9.png"
import watchseries8 from "./models/Watch/Series 8.png"
import watchse from "./models/Watch/SE.png"

import ps5 from "./models/Sony/Playstation 5.png"
import dualsense from "./models/Sony/Dualsense.png"
import psplus from "./models/Sony/PS Plus.png"

import galaxys from "./models/Samsung/Galaxy S.png"
import galaxybuds from "./models/Samsung/Buds.png"

const ModelButton = styled(Button)`
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
`

export function IphoneModelsList() {
    const { model } = useParams()
    return (
        <div class="models-body">
            <div class="models-container">
                { model !== "all" ? <ModelListItem image={all} title="Все" to="/iphone/all"/> : <></> }
                <ModelListItem image={iphone16promax} title="16 Pro Max" to="/iphone/16 Pro Max"/>
                <ModelListItem image={iphone16pro} title="16 Pro" to="/iphone/16 Pro"/>
                <ModelListItem image={iphone16} title="16 | 16 Plus" to="/iphone/16"/>
                <ModelListItem image={iphone15} title="15 | 15 Plus" to="/iphone/15"/>
                <ModelListItem image={iphone14} title="14" to="/iphone/14"/>
                <ModelListItem image={iphone13} title="13" to="/iphone/13"/>
            </div>
        </div>
    )
}

//<ModelListItem image={iphone14pro} title="14 Pro | Max" to="/iphone/14 Pro"/>

export function MacModelsList() {
    const { model } = useParams()
    return (
        <div class="models-body">
            <div class="models-container">
                { model !== "all" ? <ModelListItem image={all} title="Все" to="/mac/all"/> : <></> }
                <ModelListItem image={macbookair} title="MacBook Air" to="/mac/MacBook Air"/>
            </div>
        </div>
    )
}

export function IpadModelsList() {
    const { model } = useParams()
    return (
        <div class="models-body">
            <div class="models-container">
                { model !== "all" ? <ModelListItem image={all} title="Все" to="/ipad/all"/> : <></> }
                <ModelListItem image={ipadair} title="Air" to="/ipad/Air"/>
                <ModelListItem image={ipad9} title="9" to="/ipad/9"/>
            </div>
        </div>
    )
}

export function WatchModelsList() {
    const { model } = useParams()
    return (
        <div class="models-body">
            <div class="models-container">
                { model !== "all" ? <ModelListItem image={all} title="Все" to="/watch/all"/> : <></> }
                <ModelListItem image={watchseries10} title="Series 10" to="/watch/Series 10"/>
                <ModelListItem image={watchseries9} title="Series 9" to="/watch/Series 9"/>
                <ModelListItem image={watchseries8} title="Series 8" to="/watch/Series 8"/>
                <ModelListItem image={watchse} title="SE" to="/watch/SE"/>
            </div>
        </div>
    )
}

export function SonyModelsList() {
    const { model } = useParams()
    return (
        <div class="models-body">
            <div class="models-container">
                { model !== "all" ? <ModelListItem image={all} title="Все" to="/sony/all"/> : <></> }
                <ModelListItem image={ps5} title="PS5" to="/sony/PlayStation 5"/>
                <ModelListItem image={dualsense} title="Dualsense" to="/sony/Dualsense"/>
                <ModelListItem image={psplus} title="PS Plus" to="/sony/PS Plus"/>
            </div>
        </div>
    )
}

export function SamsungModelsList() {
    const { model } = useParams()
    return (
        <div class="models-body">
            <div class="models-container">
                { model !== "all" ? <ModelListItem image={all} title="Все" to="/samsung/all"/> : <></> }
                <ModelListItem image={galaxys} title="Galaxy S" to="/samsung/Galaxy S"/>
                <ModelListItem image={galaxybuds} title="Buds" to="/samsung/Galaxy Buds"/>
            </div>
        </div>
    )
}

export function ModelListItem({image, title, to}) {
    return (
        <>
            <ModelButton to={to}>
                <img src={image} class="model-button-image"/>
                <div class="model-button-text">
                    {title}
                </div>
            </ModelButton>
        </>
    )
}

export function CurrentModelTitle({original, model}) {
    return (
        <div class="current-model-title-body">
            <div class="current-model-title-container">
                { model === "all" ? <>Все {original}</> : <>{original} {model}</>}
            </div>
        </div>
    )
}

export function CurrentModelTitleOnlyModel({original, model}) {
    return (
        <div class="current-model-title-body">
            <div class="current-model-title-container">
                { model === "all" ? <>Все {original}</> : <>{model}</>}
            </div>
        </div>
    )
}